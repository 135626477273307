.desktop {
    display: block;
}

.desktopAlert {
    display: none;
}

.content {
    padding: 0 16px 16px 16px;
}

input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.authLeft {
    min-height: 100vh;
    background-color: #fff;
    border-radius: 1.5625rem;
}

.customCheck input[type="checkbox"] {
    appearance: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0.25rem;
    width: 1.125rem;
    height: 1.125rem;
    background-color: #F6F3EC;
    /* border: 0.125rem solid var(--border_color2); */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customCheck input[type="checkbox"]:after {
    content: "\2713";
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
    display: none;
}

.customCheck input[type="checkbox"]:checked {
    background-color: var(--border_color);
    border: 0.125rem solid var(--border_color);
}

.customCheck input[type="checkbox"]:checked::after {
    display: block;
}

.customCheck input[type="checkbox"]:disabled {
    cursor: no-drop;
}

.anchorTag {
    text-decoration: none;
    color: var(--text7) !important;
}

.anchorTag:hover {
    color: var(--text7) !important;
}

.fullWidthButton {
    width: 100%;
    background-color: var(--btn_color1) !important;
    color: var(--text4) !important;
}

.otpInputs input[type="tel"] {
    width: 3.125rem;
    height: 3.125rem;
    text-align: center;
    border-radius: 0.25rem;
}

.main_sidebar_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0.125rem 0 0.625rem rgba(0, 0, 0, 0.04);
    /* height: 100%; */
    height: 100vh;
    padding: 0.313rem 0 0.313rem 0.313rem;
    overflow: hidden;
    /* z-index: 2; */
}

.inside_sidebar_wrapper {
    padding: 0 0 0.938rem 0;
    height: 100vh;
    display: grid;
    grid-template-rows: max-content auto;
}

.mobile_sidebar_wrapper {
    display: none;
}

.logo_wrapper {
    padding: 1.25rem 0.938rem;
}

.logo_wrapper_collapse {
    padding: 1.25rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menus_wrapper {
    padding: 0 0 0 0;
    overflow: auto;
    /* height: 100vh; */
}

.menus_wrapper::-webkit-scrollbar {
    display: none;
}

.menu_highlighter_wrapper {
    display: flex;
    /* border-bottom: 2px solid var(--border_color4); */
}

.single_menu {
    display: flex;
    align-items: center;
    padding: 8px 0.938rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.1s linear;
    width: 100%;
}

.single_menu_active {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 0.938rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.1s linear;
    background-color: var(--bg_color1);
    width: 100%;
}

.menu_highlight {
    width: 0.188rem;
    height: 2.3rem;
    margin-top: 2px;
    border-radius: 1px;
    margin-left: 2px;
}

.single_menu:hover {
    background-color: var(--bg_color1);
    transition: all 0.1s linear;
}

.menu {
    margin-left: 0.625rem;
}

.sub_menu {
    margin-left: 6px;
}

.header_wrapper {
    padding: 20px 0;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #F6F3EC;
    z-index: 2;
}

.menu_collapse {
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.menu_collapse svg {
    font-size: 20px;
    stroke-width: 1px;
}

.user_menu_wrapper {
    border-radius: 8px;
    background-color: #ffffff;
    padding: 8px 12px;
    cursor: pointer;
    position: relative;
}

.user_menus {
    position: absolute;
    top: 50px;
    right: 0;
    border-radius: 8px;
    background-color: #ffffff;
    min-width: 150px;
    z-index: 100;
    border: 1px solid var(--border_color);
}

.user_menus::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 10px;
    width: 10px;
    height: 10px;
    display: block;
    background-color: #ffffff;
    transform: rotate(45deg);
    border-top: 1px solid var(--border_color);
    border-left: 1px solid var(--border_color);
}

.user_menus .menus {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border-bottom: 1px solid var(--border_color);
}

.user_menus .menus:last-child {
    border: none;
}

.user_menus .menus span {
    color: var(--text2);
}

.user_image_wrapper {
    position: relative;
    cursor: pointer;
}

.status_dot {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #6BDA9F;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    line-height: 12px;
    border: 1px solid #fff;
}

.buttonOne {
    background-color: var(--btn_color1) !important;
    color: var(--text4) !important;
    /* padding: 10px 12px !important; */
    border-radius: 8px !important;
    height: 32px;
    width: max-content;
    display: flex !important;
    align-items: center;
}

.buttonTwo {
    background-color: #fff !important;
    outline: 1px solid var(--border_color) !important;
    color: var(--text2) !important;
    border-radius: 8px !important;
    padding: 3px 10px !important;
}

.btn_without_color {
    outline: 1px solid var(--border_color) !important;
    color: var(--text2) !important;
    border-radius: 4px !important;
    padding: 3px 10px !important;
    height: 31px;
}

.btn_with_color {
    background-color: var(--bg_color2) !important;
    color: #fff !important;
    border-radius: 4px !important;
    padding: 3px 10px !important;
    height: 32px;
}

.custom_btn {
    background-color: var(--bg_color2) !important;
    color: #fff !important;
    padding: 0px 20px !important;
    height: 40px;
    border-radius: 4px !important;
    height: 32px;
}

.custom_btn_outline {
    background-color: #fff !important;
    color: var(--text2) !important;
    padding: 0px 20px !important;
    height: 32px;
    border-radius: 4px !important;
    border: 1px solid var(--border_color) !important;
}

.modalBtnColor {
    color: #fff !important;
    padding: 0px 20px !important;
    height: 32px;
    border-radius: 4px !important;
}

.modalBtnOutline {
    background-color: #fff !important;
    color: var(--text1) !important;
    padding: 0px 20px !important;
    height: 32px;
    border-radius: 4px !important;
    border: 1px solid var(--border_color2) !important;
}

.single_page {
    background-color: #fff;
    border-radius: 8px;
    padding-bottom: 16px;
    overflow: hidden;
    min-height: calc(100vh - 100px);
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.filter_wrapper {
    padding: 16px;
    background-color: #fff;
    border-bottom: 1px solid var(--border_color4);
}

.list_view_wrapper {
    padding: 16px 16px 0 16px;
}

.dropdown_filter_wrapper {
    overflow: auto;
}

.dropdown_filter_wrapper::-webkit-scrollbar {
    display: none;
}

.dropdown_filter_section {
    width: auto;
}


.sort_btn {
    /* width: 41px; */
    height: 28px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.sort_btn:hover {
    background-color: var(--bg_color2) !important;
    color: #fff !important;
}

.sort_btn:last-child {
    margin-right: 0;
}

.paginationBtn {
    padding: 3px 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.1s linear;
}

.paginationBtn:hover {
    background-color: var(--bg_color2);
    color: #fff;
    transition: all 0.1s linear;
}

.paginationDots {
    padding: 3px 12px;
    border-radius: 8px;
}

.paginationDots:hover {
    background-color: transparent;
}

.selected {
    background-color: var(--bg_color2);
    color: #fff;
}

th,
td {
    border-bottom: none !important;
    padding: 12px 10px !important;
}

.table_head {
    background-color: var(--bg_color4) !important;
}

.table_head_small {
    background-color: #fff !important;
    border-bottom: 1px solid var(--border_color4) !important;
}

.table_head_list {
    background-color: var(--bg_color6) !important;
}

.table_wrapper {
    width: max-content;
    min-width: 100%;
}

tbody tr:hover {
    background-color: var(--bg_color4);
}

.searchWrapper {
    max-width: 270px;
    height: 32px;
    display: flex;
    border-radius: 8px !important;
    outline: 1px solid var(--border_color4);
    color: var(--text3) !important;
    justify-content: space-between;
}

.searchWrapper input {
    border: none !important;
    height: 32px;
    font-weight: 400;
    font-size: 12px;
}

.searchWrapper input[type="text"]::placeholder {
    color: var(--text5);
    font-weight: 500;
    font-size: 12px;
}

.searchBtn {
    height: 100%;
    padding: 0 10px;
    background-color: var(--bg_color2);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    outline: none;
    border: none;
}

.crossBtn {
    height: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text1);
    cursor: pointer;
    margin-right: 6px;
}

.date_inputs input[type="date"] {
    height: 32px;
    border-radius: 4px !important;
    background-color: var(--bg_color4) !important;
    border: none !important;
    color: var(--text3) !important;
    font-size: 12px !important;
    padding: 0 10px;
    outline: none;
    font-weight: 400;
}

.date_inputs input[type="date"]::placeholder {
    color: var(--text5) !important;
    font-weight: 500;
    font-size: 12px;
}

.inside_single_page {
    padding: 100px 10px 80px 10px;
}

.search_wrapper {
    max-width: 600px;
    width: 100%;
}

.search_wrapper_section {
    background-color: #fff;
    border-radius: 14px;
    padding: 10px;
}

.search_field_section {
    padding: 10px 20px;
    background-color: var(--bg_color4);
    border-radius: 8px;
}

.company_search {
    border: none !important;
    background-color: var(--bg_color4) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.company_search::placeholder {
    color: var(--text5) !important;
}

.search_result_wrapper {
    height: auto;
    max-height: 200px;
    overflow: auto;
}


.search_result_wrapper::-webkit-scrollbar {
    display: none;
}

.search_result_section {
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid var(--border_color4);
    border-radius: 8px;
}

.search_result_section:hover {
    background-color: var(--bg_color5);
    border-radius: 8px;
}

.search_result_section:last-child {
    border-bottom: none;
}

/* .details_form_wrapper {
    padding: 20px 16px;
} */

.step_wrapper {
    background-color: var(--bg_color3);
    border-radius: 8px;
    padding: 3px 15px;
}

.input_wrapper {
    border-radius: 8px;
    border: 2px solid var(--border_color4);
    padding: 3px 0px 3px 0;
    background-color: #fff;
}

.input_field {
    border: none !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    color: var(--text3);
}

.input-group {
    border-radius: 8px !important;
    border: 2px solid var(--border_color4) !important;
    height: 40px !important;
}

.input-group-text {
    min-width: 40px !important;
    justify-content: center !important;
    height: 100%;
    border-radius: 0px !important;
}

.input_field::placeholder {
    color: var(--text5) !important;
}

.errorDanger {
    color: var(--error);
    font-size: 12px;
    font-weight: 600;
}

.icon_wrapper {
    width: 40px;
    height: 40px;
    background-color: var(--bg_color2);
    padding: 8px 12px !important;
    border-radius: 8px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.bell_wrapper {
    position: relative;
}

.bell_dot {
    position: absolute;
    top: 0px;
    right: 2px;
    background-color: var(--error);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    line-height: 12px;
    border: 1px solid var(--border_color);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
}

.notification_wrapper {
    position: absolute;
    top: 50px;
    right: 0;
    border-radius: 8px;
    background-color: #fff;
    width: 330px;
    /* min-width: 330px; */
    z-index: 100;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
}

.notification_wrapper::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 10px;
    width: 10px;
    height: 10px;
    display: block;
    background-color: var(--bg_color5);
    transform: rotate(45deg);
}

.notification_heading {
    background-color: var(--bg_color5);
    padding: 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.single_notification:hover {
    background-color: var(--bg_color5);
}

.noti_popup_wrapper {
    position: absolute;
    top: 50px;
    right: 0;
    width: 330px;
    background-color: var(--bg_color1);
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
}

.noti_popup_wrapper::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 10px;
    width: 10px;
    height: 10px;
    display: block;
    background-color: var(--bg_color1);
    transform: rotate(45deg);
}

.filter_section_wrapper {
    background-color: var(--bg_color2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    cursor: pointer;
}

.filter_option_wrapper {
    position: absolute;
    top: 40px;
    right: 0;
    min-width: 200px;
    width: max-content;
    background-color: #fff;
    box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 1px solid var(--border_color4);
    padding: 0 5px;
    z-index: 1;
}

.delete_modal_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* z-index: 3; */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.delete_modal_section {
    background-color: #fff;
    border-radius: 8px;
    padding: 25px;
    /* width: max-content; */
    width: 300px;
}

.person_name_wrapper {
    background-color: #fff;
    border-radius: 8px;
    padding: 12px 20px 12px 20px;
}

.details_menu_wrapper {
    background-color: #fff;
    border-radius: 8px;
    padding: 6px;
    margin-top: 6px;
    min-height: calc(100vh - 148px);
    height: calc(100vh - 148px);
    overflow-y: auto;
}

.details_menu_wrapper::-webkit-scrollbar {
    display: none;
}

.details_single_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 15px;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 6px;
    transition: all 0.1s linear;
    width: 100%;
}

.details_single_menu_active {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 15px;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 6px;
    transition: all 0.1s linear;
    background-color: var(--bg_color1);
    width: 100%;
}

.details_single_menu:hover {
    background-color: var(--bg_color1);
    transition: all 0.1s linear;
}

.details_single_menu:last-child,
.details_single_menu_active:last-child {
    margin-bottom: 0;
}

.viewlead_content_header {
    height: 46px;
    display: flex;
    align-items: center;
}

.viewlead_content_header_section {
    overflow: auto;
}

.viewlead_content_header_section::-webkit-scrollbar {
    display: none;
}

.activities_section_wrapper {
    border-radius: 8px;
    background-color: #fff;
    padding: 20px;
    margin-top: 6px;
    /* max-height: calc(100vh - 148px); */
    min-height: calc(100vh - 320px);
    height: calc(100vh - 320px);
    overflow: auto;
}

.approval_wrapper {
    background-color: #fff;
    padding: 20px;
    margin-top: 10px;
    border: 1px solid var(--bg_color1);
    border-radius: 8px;
}

.activities_section_wrapper::-webkit-scrollbar {
    display: none;
}

.activities {
    padding: 6px;
    border: 1px solid #E7D4FF;
    border-radius: 8px;
    background-color: rgba(219, 191, 255, 0.2);
    margin-bottom: 10px;
}

.activities:last-child {
    margin-bottom: 0;
}

.activity_dot {
    width: 4px;
    height: 4px;
    background-color: var(--bg_color2);
    border-radius: 50%;
}

.menu_content_wrapper {
    background-color: #fff;
    border-radius: 8px;
    margin-top: 6px;
    overflow: auto;
    min-height: calc(100vh - 148px);
    height: calc(100vh - 148px);
}

.menu_content_wrapper::-webkit-scrollbar {
    display: none;
}

.profile_menu_section {
    padding: 5px 20px 0px 20px;
    border-bottom: 1px solid var(--border_color4);
    overflow-x: scroll !important;
    column-gap: 30px;
}

.profile_menu_section::-webkit-scrollbar {
    display: none;
}


.profile_menus {
    width: max-content;
    padding: 10px 10px 10px 10px;
    line-height: 12px;
    color: var(--text5);
    cursor: pointer;
}

.profile_menus_active {
    width: max-content;
    padding: 10px 10px 10px 10px;
    line-height: 12px;
    color: var(--text2);
    cursor: pointer;
    outline: 1px solid var(--border_color4);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}


/* tostify custom css */
.Toastify__toast-body {
    font-size: 12px !important;
    font-weight: 500 !important;
}

/* button {
    height: 32px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
} */

.profile_menu_content_section {
    padding: 16px 20px;
}

.form_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.3);
}

.form_container {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 480px;
    width: max-content;
    height: 100%;
    box-shadow: -4px 4px 24px rgba(0, 0, 0, 0.25);
    overflow-y: scroll;
    background-color: #fff;
}

.form_container::-webkit-scrollbar {
    display: none;
}

.form_heading_section {
    position: sticky;
    top: 0;
    z-index: 10;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0.125rem 0 0.625rem rgb(0 0 0 / 4%);
    padding: 0 25px;
    background-color: #fff;
}

.form_heading_section svg {
    font-size: 16px;
    cursor: pointer;
}

.form_bottom_section {
    position: sticky;
    bottom: 0;
    z-index: 10;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0.125rem 0 0.625rem rgb(0 0 0 / 4%);
    padding: 15px 25px;
    background-color: #fff;
}

.form_fields_wrapper {
    padding: 15px 25px;
}

.form_button_wrapper {
    position: sticky;
    bottom: 0;
    z-index: 10;
    width: 100%;
    height: 80px;
    padding: 0 25px;
    background-color: #fff;
}

.input_click_button {
    /* border-right: 2px solid var(--border_color4); */
    padding: 8px 10px;
}

.back_section {
    height: 46px;
    display: flex;
    align-items: center;
}

.form_section {
    margin-top: 6px;
}

.entry_table_wrapper {
    width: 100%;
    overflow-x: auto;
    /* border-bottom: 1px solid var(--border_color4); */
}

.entry_table_wrapper::-webkit-scrollbar {
    display: none;
}

.calculation_wrapper {
    width: 250px;
}

.view_doc_menu_wrapper {
    background-color: #fff;
    border-radius: 8px;
    padding: 6px;
    margin-top: 6px;
    min-height: calc(100vh - 148px);
    height: calc(100vh - 148px);
    overflow-y: auto;
}

.view_doc_menu_wrapper::-webkit-scrollbar {
    display: none;
}

.view_single_doc_menu {
    padding: 10px 15px 10px 15px;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 6px;
    transition: all 0.1s linear;
    width: 100%;
}

.view_single_doc_menu:hover {
    background-color: var(--bg_color1);
    transition: all 0.1s linear;
}

.view_single_doc_menu_active {
    padding: 10px 15px 10px 15px;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 6px;
    transition: all 0.1s linear;
    width: 100%;
    background-color: var(--bg_color1);
}

.view_doc_header {
    background-color: #fff;
    padding: 0 16px;
    border-radius: 8px;
}

.dropdown_filter {
    height: 32px;
    line-height: 1px;
    background-color: var(--bg_color4) !important;
    border: none !important;
}

.radioCustom input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    padding: 2px;
    background-clip: content-box;
    background-color: var(--bg_color3);
    border-radius: 50%;
    cursor: pointer;
}

.radioCustom input[type="radio"]:checked {
    background-color: #fff;
    border: 6px solid var(--bg_color2);
}

.radioCustom label {
    cursor: pointer;
}

.pagination_wrapper {
    position: relative;
    width: 100%;
}

.per_page_container {
    top: 0;
    padding: inherit;
    position: absolute;
    right: 0;
}

.error_404_wrapper {
    padding: 16px;
    width: 100%;
    height: 100vh;
}

.error_404_container {
    background-color: #fff;
    border-radius: 8px;
    overflow: auto;
    height: 100%;
}

.error_404_container::-webkit-scrollbar {
    display: none;
}

.tags {
    background-color: #fff;
    border-radius: 8px;
    padding: 5px 10px;
    border: 2px solid var(--border_color4);
    margin-right: 10px;
}

.tags:last-child {
    margin-right: 0;
}

.color_dropdown_wrapper {
    border-radius: 8px;
    background-color: #fff;
    padding: 6px 12px;
    cursor: pointer;
    position: relative;
    min-width: 92px;
    width: max-content;
    border: 1px solid #000;
}

.color_dropdown_menus_section {
    position: absolute;
    top: 35px;
    left: 0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    z-index: 100;
    border: 1px solid #000;
}

.color_dropdown_menus_section .color_dropdown_menus {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    border-bottom: 1px solid #000;
}

.color_dropdown_menus_section .color_dropdown_menus:last-child {
    border-bottom: none;
}

.card_wrapper {
    background-color: var(--bg_color3);
    border-radius: 8px;
    padding: 10px;
}

.card_body {
    background-color: #fff;
    border-radius: 6px;
    padding: 10px;
}

.priority_div {
    color: #fff;
    padding: 4px 8px;
    background-color: var(--text1);
    border-radius: 4px;
}

.kebab_menus_section {
    position: absolute;
    top: 25px;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0 10px;
}

.kebab_menus {
    padding: 2px 0;
    border-bottom: 1px solid var(--border_color4);
    cursor: pointer;
}

.kebab_menus:last-child {
    border-bottom: none;
}

.multi_data_wrapper {
    display: flex;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
}

.indi_multi_data {
    position: relative;
    border-radius: 4px;
    border: 1px solid var(--border_color4);
    padding: 3px 10px;
}

.remove_data {
    position: absolute;
    top: -5px;
    right: -5px;
    border: 1px solid var(--border_color4);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
}

.add_icon_wrapper {
    position: absolute;
    bottom: 9px;
    right: 9px;
}

.assignTo_section {
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--bg_color2);
    color: #fff;
    cursor: pointer;
}

.loader_wrapper {
    overflow: auto;
}

.loader_wrapper::-webkit-scrollbar {
    display: none;
}

.activityTag {
    color: var(--text1);
    padding: 2px 8px;
    border-radius: 12px;
    background-color: #E7D4FF;
}

.doc_wrapper {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.doc_table {
    border-collapse: collapse;
    width: 100%;
}

.doc_table td,
.doc_table th {
    border: 1px solid var(--border_color4) !important;
    text-align: left;
    padding: 5px 10px !important;
}

.doc_table td {
    font-size: 12px;
    font-weight: 500;
    color: var(--text1);
}

.doc_table th {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
}

.doc_table .no-border {
    border: 0px !important;
}

.doc_table .no-top-border {
    border-top: 0px !important;
}

.profile_image_wrapper {
    width: 200px;
    height: 200px;
    border: 2px dotted var(--border_color4);
    border-radius: 8px;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select_profile_image {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.menu_heading_wrapper {
    padding: 0 0 0 15px;
    margin: 5px 0;
}

.ant-switch-checked {
    background-color: var(--bg_color2) !important;
}

.setting_input_wrapper {
    width: 300px;
}

.image_wrapper {
    position: relative;
}

.delete_file {
    position: absolute;
    top: -7px;
    right: -7px;
    border-radius: 50%;
    border: 1px solid var(--border_color2);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.delete_file img {
    width: 15px;
}

.has_profile_photo {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    overflow: hidden;
}

.main_loader_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90000;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new_btn_wrapper {
    position: relative;
}

.new_btn_menus {
    position: absolute;
    top: 42px;
    right: 0;
    border-radius: 8px;
    background-color: var(--bg_color3);
    width: 140px;
    z-index: 100;
}

.new_btn_menus::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 10px;
    width: 10px;
    height: 10px;
    display: block;
    background-color: var(--bg_color3);
    transform: rotate(45deg);
}

.new_btn_menus .menus {
    padding: 8px 20px;
    border-bottom: 1px solid var(--border_color3);
}

.new_btn_menus .menus:last-child {
    border: none;
}

.infinite-scroll-component::-webkit-scrollbar {
    display: none;
}

.selected_date_wrapper {
    width: max-content;
    background-color: var(--bg_color4);
    padding: 7px 15px;
    border-radius: 4px;
    color: var(--text5);
}

.unread_dot {
    margin: auto;
    width: 3px;
    height: 3px;
    background-color: var(--bg_color2);
    border-radius: 50%;
}

.paySlip_wrapper {
    background-color: #fff;
    border-radius: 8px;
    padding: 25px 0;
    min-width: 650px;
    max-width: 650px;
    height: 100%;
    min-height: 500px;
    overflow: hidden;
    scrollbar-gutter: stable;
    transition: all 0.5s ease;
    margin: 0 auto;
}

.paySlip_wrapper:hover {
    overflow: auto;
    transition: all 0.5s ease;
}

.paySlip_structure_wrapper {
    border: 1px solid rgb(30, 28, 28);
}

.paySlip_table_structure {
    display: grid;
    grid-template-columns: auto 40%;
    padding: 0 5px;
}

.paySlip_table_structure .table1 th {
    width: calc(60% / 3);
    text-align: center;
}

.paySlip_table_structure .table2 th {
    width: calc(100% / 2);
    text-align: center;
}

.paySlip_table_structure .table1 {
    border: 1px solid #000 !important;
}

.paySlip_table_structure .table2 {
    border: 1px solid #000 !important;
    height: fit-content;
}

.paySlip_table_structure tr:last-child {
    border-bottom: 1px solid #000 !important;
    border-top: 1px solid #000 !important;
}

.paySlip_table_structure th,
.paySlip_table_structure td {
    border-right: 1px solid #000 !important;
    padding: 5px 5px !important;
}

.paySlip_table_structure .table2 th:last-child,
.paySlip_table_structure .table2 td:last-child {
    border-right: none !important;
}

.paySlip_wrapper::-webkit-scrollbar {
    width: 8px;
}

.paySlip_wrapper::-webkit-scrollbar-track {
    display: none;
}

.paySlip_wrapper::-webkit-scrollbar-thumb {
    background: var(--bg_color6);
    border-radius: 10px;
}

.indication {
    animation: fadeIn 0.7s;
}

.upload_doc_wrapper {
    border: 2px dashed var(--border_color4);
    border-radius: 8px;
    padding: 5px 15px;
    cursor: pointer;
}

.selected_doc_wrapper {
    position: relative;
    border: 2px solid var(--border_color);
    border-radius: 8px;
    padding: 5px 15px;
}

.crossFile {
    position: absolute;
    bottom: 23px;
    right: -7px;
    cursor: pointer;
}

.outline-div-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--border_color);
    height: 32px;
    padding: 0 12px;
}

.calendar_color_schemes_wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
}

.calendar_color_schemes_btn_click {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--bg_color2);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar_color_schemes_section {
    position: absolute;
    bottom: 48px;
    right: 14px;
    min-width: 200px;
    width: max-content;
    background-color: #fff;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 8px;
    border: 1px solid var(--border_color4);
    padding: 0 10px;
    z-index: 1;
}

.select_country_section {
    width: 100%;
    position: absolute;
    top: 45px;
    border-radius: 8px !important;
    border: 2px solid var(--border_color4);
    background-color: #fff;
    padding-bottom: 5px;
    height: max-content;
    max-height: 150px;
    overflow-y: scroll;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.select_country_section .countries {
    font-size: 12px;
    font-weight: 600;
    padding: 8px 10px;
    color: var(--text1);
    cursor: pointer;
    transition: all 0.1s linear;
}

.select_country_section .countries:hover {
    transition: all 0.1s linear;
    background-color: var(--bg_color5);
}

.search_wrapper_inside_dropdown {
    background-color: inherit;
    padding: 8px 10px;
    position: sticky;
    top: 0;
}

.input_without_style {
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--border_color4);
    outline: none;
    padding: 8px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
}

.input_without_style input[type="text"]::placeholder {
    color: var(--text5);
    font-weight: 500;
    font-size: 12px;
}

.form-select:disabled {
    cursor: no-drop;
}

.btnOpacityLight:hover {
    opacity: 0.6;
}

@keyframes fadeIn {
    from {
        background-color: var(--bg_color6);
    }

    to {
        background-color: transparent;
    }
}

@media only screen and (max-width: 1050px) {
    .main {
        display: none;
    }

    .loginSection {
        display: none;
    }

    .desktopAlert {
        display: block;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 30px;
    }
}

@media only screen and (max-width: 992px) {

    .filter_option_wrapper {
        left: 0px
    }

    .dropdown_filter_section {
        width: max-content;
    }
}

@media only screen and (max-width: 768px) {
    /* .new_btn_menus {
        position: absolute;
        left: 0;
    }

    .new_btn_menus::before {
        left: 10px;
    } */
}

@media only screen and (max-width: 576px) {
    .desktop {
        display: none;
    }

    .searchWrapper {
        max-width: 100%;
    }

    .pagination_wrapper {
        position: unset;
        margin-top: 30px;
        padding: 0;
    }

    .per_page_container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        padding: 0;
        position: unset;
    }

    .form_container {
        min-width: auto;
        width: 100%;
    }

    .main_sidebar_wrapper {
        display: none;
    }

    .content {
        margin: 0 !important;
    }

    .mobile_sidebar_wrapper {
        display: flex;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .mobile_main_sidebar_wrapper {
        background-color: #fff;
        box-shadow: 0.125rem 0 0.625rem rgba(0, 0, 0, 0.04);
        height: 100%;
        padding: 0.313rem 0 0.313rem 0.313rem;
        width: 14rem;
    }

    .setting_input_wrapper {
        width: 100%;
    }

    .notification_wrapper {
        width: 240px;
    }

    .calculation_wrapper {
        width: 100%;
    }

    .delete_modal_section {
        width: 100%;
    }
}