@font-face {
    font-family: NotoColorEmojiLimited;
    unicode-range: U+1F1E6-1F1FF;
    src: url(https://raw.githack.com/googlefonts/noto-emoji/main/fonts/NotoColorEmoji.ttf);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box !important;
    font-family: 'NotoColorEmojiLimited', "Roboto", sans-serif !important;
}

body {
    background-color: #F6F3EC !important;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    display: none;
}

::-webkit-scrollbar-thumb {
    background: var(--text5);
    border-radius: 10px;
}

:root {
    --error: #FF808B;
    --btn_color1: #c1964e;
    --btn_color2: #FF808B;
    --text1: #77777A;
    --text2: #42210b;
    --text3: #1C1D21;
    --text4: #ffffff;
    --text5: #A4A5A6;
    --text6: #6BDA9F;
    --text7: #c1964e;
    --border_color: #c1964e;
    --border_color2: #77777A;
    --border_color3: #8081A5;
    --border_color4: #F0F0F3;
    --border_color5: #e3e6ea;
    --bg_color1: #ebe1ce;
    --bg_color2: #c1964e;
    --bg_color3: #EAEFF3;
    --bg_color4: #F6F6F6;
    --bg_color5: #F5F5FA;
    --bg_color6: #D9D9D9;
    --bg_color7: #42210b;
}

.font8x400 {
    font-size: 8px !important;
    font-weight: 400 !important;
}

.font10x400 {
    font-size: 10px !important;
    font-weight: 400 !important;
}

.font10x500 {
    font-size: 10px !important;
    font-weight: 500 !important;
}

.font10x600 {
    font-size: 10px !important;
    font-weight: 600 !important;
}

.font12x400 {
    font-size: 12px !important;
    font-weight: 400 !important;
}

.font12x500 {
    font-size: 12px !important;
    font-weight: 500 !important;
}

.font12x600 {
    font-size: 12px !important;
    font-weight: 600 !important;
}

.font13x500 {
    font-size: 13px !important;
    font-weight: 500 !important;
}

.font14x400 {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.font14x500 {
    font-size: 14px !important;
    font-weight: 500 !important;
}

.font14x600 {
    font-size: 14px !important;
    font-weight: 600 !important;
}

.font16x500 {
    font-size: 16px !important;
    font-weight: 500 !important;
}

.font16x600 {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.font16x400 {
    font-size: 16px !important;
    font-weight: 400 !important;
}

.font18x600 {
    font-size: 18px !important;
    font-weight: 600 !important;
}

.font20x400 {
    font-size: 20px !important;
    font-weight: 400 !important;
}

.font20x600 {
    font-size: 20px !important;
    font-weight: 600 !important;
}

.font22x600 {
    font-size: 22px !important;
    font-weight: 600 !important;
}

.font54x400 {
    font-size: 54px !important;
    font-weight: 400 !important;
}

.font96x600 {
    font-size: 96px !important;
    font-weight: 600 !important;
}

.textColor1 {
    color: var(--text1);
}

.textColor2 {
    color: var(--text2);
}

.textColor3 {
    color: var(--text3);
}

.textColor5 {
    color: var(--text5);
}

.textColor6 {
    color: var(--text6);
}

.textColor7 {
    color: var(--text7);
}

.bgColor1 {
    background-color: var(--error);
}

.bgColor2 {
    background-color: var(--bg_color2);
}

.bgColor3 {
    background-color: #ffffff;
}

.cursor_pointer {
    cursor: pointer !important;
}

textarea {
    resize: none !important;
}

button:disabled {
    pointer-events: unset !important;
    cursor: no-drop !important;
}

/* print */
.print {
    padding: 0px !important;
}

.print button {
    background-color: var(--bg_color2);
    color: white;
    border: 0px;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 12px;
    border-radius: 8px;
}

.signature-text-container {
    width: 150px;
    /* margin-left: auto; */
}

.signature-container {
    border-bottom: 1px solid var(--border_color5);
    padding-bottom: 15px;
    text-align: center;
}

/* total-section  */
.total-section {
    grid-template-columns: 39% 50%;
    column-gap: 10px;
}